import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { ROUTES } from './constants';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const ManagerCar = Loader(
  lazy(() => import('src/content/management/ManagerCar/index'))
);

const DetailCar = Loader(
  lazy(() => import('src/content/management/ManagerCar/detail'))
);

const carRoutes = [
  {
    path: ROUTES.CAR,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <ManagerCar />
      },
      {
        path: ROUTES.CREATE,
        element: <DetailCar />
      },
      {
        path: ROUTES.DETAIL,
        children: [
          {
            path: ':carId',
            element: <DetailCar />
          }
        ]
      },
      {
        path: ROUTES.EDIT,
        children: [
          {
            path: ':carId',
            element: <DetailCar />
          }
        ]
      }
    ]
  }
];

export default carRoutes;
