export type ResponseType<T> = {
  status: API_STATUS_CODE;
  data: T;
  total: number;
  totalPages: number;
  limit: number;
  page: number;
};

export enum API_STATUS_CODE {
  SUCCESS = 1,
  ERROR = 2
}
