import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import carRoutes from './car';
import { ROUTES } from './constants';
import imageLauncherRoutes from './image-launcher';
import panoramicLauncherRoutes from './panoramic-launcher';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Users = Loader(lazy(() => import('src/content/management/Users')));

const SingleUser = Loader(
  lazy(() => import('src/content/management/Users/single'))
);

const Apps = Loader(lazy(() => import('src/content/management/Apps')));
const DetailApp = Loader(
  lazy(() => import('src/content/management/Apps/detail'))
);
const Categories = Loader(
  lazy(() => import('src/content/management/Category'))
);
const DetailCategory = Loader(
  lazy(() => import('src/content/management/Category/detail'))
);
const Banners = Loader(lazy(() => import('src/content/management/Banners')));
const BannerDetail = Loader(
  lazy(() => import('src/content/management/Banners/detail'))
);
const Products = Loader(
  lazy(() => import('src/content/management/Products/index'))
);
const Launcher = Loader(
  lazy(() => import('src/content/management/Apps/launcher/index'))
);
const LauncherCodeType = Loader(
  lazy(() => import('src/content/management/Apps/launcher-code-type/index'))
);
const DetailProduct = Loader(
  lazy(() => import('src/content/management/Products/detail'))
);
const DarftOrder = Loader(
  lazy(() => import('src/content/management/DraftOrder/index'))
);
const ProductWarehouses = Loader(
  lazy(() => import('src/content/management/ProductWarehouse'))
);
const SystemWarehouses = Loader(
  lazy(() => import('src/content/management/SystemWarehouse'))
);
const SystemWarehousesImport = Loader(
  lazy(() => import('src/content/management/SystemWarehouse/import'))
);
const MerchantWarehouses = Loader(
  lazy(() => import('src/content/management/MerchantWarehouse'))
);
const ProductWarehouseImport = Loader(
  lazy(() => import('src/content/management/ProductWarehouse/import'))
);

const Warehouses = Loader(
  lazy(() => import('src/content/management/Warehouse/index'))
);
const DetailWarehouse = Loader(
  lazy(() => import('src/content/management/Warehouse/detail'))
);

const managementRoutes = [
  {
    path: '',
    element: <Navigate to={ROUTES.APPS} replace />
  },
  {
    path: ROUTES.APPS,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <Apps />
      },
      {
        path: ROUTES.CREATE,
        element: <DetailApp />
      },
      {
        path: ROUTES.DETAIL,
        children: [
          {
            path: ':appId',
            element: <DetailApp />
          }
        ]
      },
      {
        path: ROUTES.EDIT,
        children: [
          {
            path: ':appId',
            element: <DetailApp />
          }
        ]
      }
    ]
  },
  {
    path: ROUTES.USERS,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <Users />
      },
      {
        path: 'single',
        children: [
          {
            path: ':userId',
            element: <SingleUser />
          }
        ]
      }
    ]
  },
  {
    path: 'banners',
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <Banners />
      },
      {
        path: ROUTES.CREATE,
        element: <BannerDetail />
      }
    ]
  },
  {
    path: ROUTES.CATEGORIES,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <Categories />
      },
      {
        path: ROUTES.CREATE,
        element: <DetailCategory />
      },
      {
        path: ROUTES.DETAIL,
        children: [
          {
            path: ':categoryId',
            element: <DetailCategory />
          }
        ]
      },
      {
        path: ROUTES.EDIT,
        children: [
          {
            path: ':categoryId',
            element: <DetailCategory />
          }
        ]
      }
    ]
  },
  {
    path: ROUTES.PRODUCTS,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <Products />
      },
      {
        path: ROUTES.CREATE,
        element: <DetailProduct />
      },
      {
        path: ROUTES.DETAIL,
        children: [
          {
            path: ':productId',
            element: <DetailProduct />
          }
        ]
      },
      {
        path: ROUTES.EDIT,
        children: [
          {
            path: ':productId',
            element: <DetailProduct />
          }
        ]
      }
    ]
  },
  {
    path: ROUTES.LAUNCHER,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <Launcher />
      }
    ]
  },
  {
    path: ROUTES.LAUNCHER_CODE_TYPE,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <LauncherCodeType />
      }
    ]
  },
  {
    path: ROUTES.DRAFT_ORDER,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <DarftOrder />
      }
    ]
  },
  {
    path: ROUTES.SYSTEM_WAREHOUSE,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <SystemWarehouses />
      },
      {
        path: 'import',
        element: <SystemWarehousesImport />
      }
    ]
  },
  {
    path: ROUTES.MERCHANT_WAREHOUSE,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <MerchantWarehouses />
      }
    ]
  },
  {
    path: ROUTES.WAREHOUSE,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <Warehouses />
      },
      {
        path: ROUTES.CREATE,
        element: <DetailWarehouse />
      },
      {
        path: ROUTES.DETAIL,
        children: [
          {
            path: ':warehouseId',
            element: <DetailWarehouse />
          }
        ]
      },
      {
        path: ROUTES.EDIT,
        children: [
          {
            path: ':warehouseId',
            element: <DetailWarehouse />
          }
        ]
      }
    ]
  },
  ...carRoutes,
  ...imageLauncherRoutes,
  ...panoramicLauncherRoutes
];

export default managementRoutes;
