import { Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';

import { Button, CircularProgress, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';

const LoginJWT: FC = () => {
  const { login } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();

  return (
    <Formik
      initialValues={{
        phoneNumber: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        phoneNumber: Yup.string().required(t('Số điện thoại là bắt buộc')),
        password: Yup.string().max(255).required(t('Mật khẩu là bắt buộc'))
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.phoneNumber, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.phoneNumber && errors.phoneNumber}
            label={t('Tên đăng nhập hoặc Số điện thoại')}
            name="phoneNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.phoneNumber}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={t('Mật khẩu')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {/* <Box
            alignItems="center"
            display={{ xs: 'block', md: 'flex' }}
            justifyContent="flex-end"
            mt={2}
          >
            <Link component={RouterLink} to="/account/recover-password">
              <b>{t('Lost password?')}</b>
            </Link>
          </Box> */}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('Đăng nhập')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
