import { RouteObject } from 'react-router';

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Authenticated from 'src/components/Authenticated';
import SuspenseLoader from 'src/components/SuspenseLoader';

import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

import accountRoutes from './account';
import { ROUTES } from './constants';
import managementRoutes from './management';

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const router: RouteObject[] = [
  {
    path: ROUTES.ACCOUNT,
    children: accountRoutes
  },
  {
    path: ROUTES.STATUS,
    children: [
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: ROUTES.MAINTENANCE,
        element: <StatusMaintenance />
      },
      {
        path: ROUTES.COMING_SOON,
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={`/${ROUTES.MANAGEMENT}`} />
      },
      {
        path: ROUTES.MANAGEMENT,
        children: managementRoutes
      }
    ]
  }
];

export default router;
