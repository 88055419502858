import { User } from 'src/models/user';
import { userAxiosInstance } from './base';
import { API_STATUS_CODE, ResponseType } from './types';

const login = async (
  phoneNumber: string,
  password: string
): Promise<
  ResponseType<{ user: User; accessToken: string; refreshToken: string }>
> => {
  return userAxiosInstance
    .post('/login', { phoneNumber, password })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return {
        status: API_STATUS_CODE.ERROR,
        data: { message: error.message }
      };
    });
};

const refreshToken = async (
  refreshToken: string
): Promise<
  ResponseType<{ user: User; accessToken: string; refreshToken: string }>
> => {
  return userAxiosInstance
    .post('/refresh', { refreshToken })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return {
        status: API_STATUS_CODE.ERROR,
        data: { message: error.message }
      };
    });
};

export default { login, refreshToken };
