import { Zoom } from "@mui/material";
import { useSnackbar } from "notistack";

interface INotify {
    variant: 'error' | 'success' | 'info';
    message: string;
}

const useNotify = () =>{

    const {enqueueSnackbar} = useSnackbar()

    const notify = ({variant, message}: INotify) => {
        enqueueSnackbar(message, {
        variant,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }

    return {notify}
}

export default useNotify