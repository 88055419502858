export const ROUTES = {
  ACCOUNT: 'account',
  STATUS: 'status',
  MANAGEMENT: 'management',
  MAINTENANCE: 'maintenance',
  COMING_SOON: 'coming-soon',
  LIST: 'list',
  CREATE: 'create',
  EDIT: 'edit',
  DETAIL: 'detail',
  USERS: 'users',
  CATEGORIES: 'categories',
  PRODUCTS: 'products',
  PRODUCT_WAREHOUSE: 'product-warehouse',
  WAREHOUSE: 'warehouse',
  APPS: 'apps',
  BANNERS: 'banners',
  DRAFT_ORDER: 'draft-order',
  CAR: 'car',
  IMAGE_LAUNCHER: 'image-launcher',
  PANORAMIC_LAUNCHER: 'panoramic-launcher',
  LAUNCHER_CODE_TYPE: 'launcher-code-type',
  LAUNCHER: 'launcher',
  MERCHANT_WAREHOUSE: 'merchant-warehouse',
  SYSTEM_WAREHOUSE: 'system-warehouse'
};
