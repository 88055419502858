import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { ROUTES } from './constants';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const PanoramicLauncher = Loader(
  lazy(() => import('src/content/management/PanoramicLauncher/index'))
);

const PanoramicLauncherDetail = Loader(
  lazy(() => import('src/content/management/PanoramicLauncher/detail'))
);

const panoramicLauncherRoutes = [
  {
    path: ROUTES.PANORAMIC_LAUNCHER,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <PanoramicLauncher />
      },
      {
        path: ROUTES.CREATE,
        element: <PanoramicLauncherDetail />
      }
    ]
  }
];

export default panoramicLauncherRoutes;
